
import { EventViewEntityModel, EventViewQueryModel } from '@common-src/entity-model/event-view-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/event`;

class EventViewService implements ICRUDQ<EventViewEntityModel, EventViewQueryModel> {
    async create(model: EventViewEntityModel):Promise<EventViewEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<EventViewEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new EventViewEntityModel().toModel(res);
    }

    async update(model: EventViewEntityModel):Promise<EventViewEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: EventViewEntityModel):Promise<EventViewEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: EventViewQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new EventViewEntityModel().toModel(item));
        return res;
    }

    async handleEvent(model: EventViewEntityModel):Promise<EventViewEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = {
            id: model.id,
            comments: model.comments
        };
        const res = await post(url, params);
        return res;
    }

    async eventDef(eventId:string):Promise<any> {
        const url = `${BI_BASE_REQUEST_PATH}/things/events/detail/${eventId}`;
        const res = await get(url);
        return res;
    }

    async exportExcel(query?: EventViewQueryModel):Promise<any> {
        const url = `${URL_PATH}/export`;
        const params = query ? query.toService() : {};
        return await post(url, params);
    }

    async batchProcess(params: any): Promise<any> {
        const url = `${URL_PATH}/batchUpdate`;
        return await post(url, params);
    }
}

export default new EventViewService();
