





































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { EventViewEntityModel } from '@common-src/entity-model/event-view-entity';
import EventViewService from '@common-src/service/event-view';
import WorkOrderDetailComponent from '@/pages/dashboard/work-order/wo-detail.vue';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        'work-order-detail-component': WorkOrderDetailComponent
    }
})
export default class EventViewDetailComponent extends Mixins(TableComponent, DrawerComponent) {
    @Prop()
    eventId: string;

    loading: boolean = false;
    eventModel: EventViewEntityModel = null;

    serviceType = '';
    processInstanceId: string = '';
    ViewModeType = ViewModeType;
    viewType:ViewModeType = ViewModeType.VIEW;
    commentsDisabled:boolean = true;

    imageVisible:boolean = false;
    selectedImg:string = '';

    @Watch('eventId', { immediate: true, deep: true })
    eventIdChange(newVal: string): void {
        if (!newVal) {
            return;
        }
        this.loading = true;
        this.tableColumns = EventViewEntityModel.getDetailColumns();
        EventViewService.retrieve(newVal).then(res => {
            this.commentsDisabled = res.status === 'YCL';
            this.eventModel = res;
        }).finally(() => {
            this.loading = false;
        });
    }

    handleEventClick() {
        if (!this.eventModel.comments) {
            this.showMessageWarning('请输入处理意见');
            return;
        }
        return EventViewService.handleEvent(this.eventModel).then(res => {
            this.commentsDisabled = true;
            this.$emit('handled');
        });
    }

    cancelClick() {
        this.$emit('close');
    }

    createWorkorder(type) {
        this.serviceType = type;
        this.showModal(ViewModeType.NEW, '');
    }
    showModal(viewType, id) {
        this.viewType = viewType;
        this.processInstanceId = id;
        this.drawerOpen(this.processInstanceId);
    }

    showDrawer(visible) {
        // this.init();
        this.drawerVisible = visible;
    }

    previewImage(imageData) {
        this.imageVisible = true;
        this.selectedImg = imageData.url;
    }

    handleOk() {
        this.imageVisible = false;
    }

    handleCancel() {
        this.imageVisible = false;
    }

    // keydown(ev) {
    //     switch (ev.key) {
    //         case 'ArrowLeft':
    //             this.$emit('change', -1);
    //             break;
    //         case 'ArrowRight':
    //             this.$emit('change', 1);
    //             break;
    //     }
    // }
}
